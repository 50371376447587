/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import constants from '@/common/constant/constants'
import config from '@/config'
import pddOrderTagstarService from './PddOrderTagstarService'
import { PddOrderTagstar, PddOrderTagstarImpl } from './types'
import { localCache } from '@/utils/cache'

const pathConfig = {
  pddOrderTagstarListPath: config.baseUrl + '/api/pdd/pddOrderTagstar/list.html',
  pddOrderTagstarAllListPath: config.baseUrl + '/api/pdd/pddOrderTagstar/allList.html',
  pddOrderTagstarFormPath: config.baseUrl + '/api/pdd/pddOrderTagstar/form.html',
  pddOrderTagstarSavePath: config.baseUrl + '/api/pdd/pddOrderTagstar/save.do',
  pddOrderTagstarDelPath: config.baseUrl + '/api/pdd/pddOrderTagstar/delete.do'
}

/**
 * 返回订单标星列表
 * @param pddOrderTagstar
 * @returns
 */
const getPddOrderTagstarListPage = (pddOrderTagstar:PddOrderTagstar) => {
  return pddOrderTagstarService.getPddOrderTagstarListPage(pathConfig.pddOrderTagstarListPath, pddOrderTagstar)
}

/**
 * 返回全部标星列表
 * @param pddOrderTagstar
 * @returns
 */
const getPddOrderTagstarAllList = (callback?:(e:PddOrderTagstar[])=>void) => {
  const pddOrderTagStarResult = pddOrderTagstarService.commonGetRequest<null, PddOrderTagstar[]>(pathConfig.pddOrderTagstarAllListPath)
  pddOrderTagStarResult.then(res => {
    if (res !== undefined) {
      localCache.setSingleStorage(constants.TAG_STAR_CACHE, res)
      if (callback !== undefined) {
        callback(res)
      }
    }
  })
}

/**
 * 返回缓存中的标星列表
 * @returns
 */
const getPddOrderTagstar = () => {
  return localCache.getSingleStorage(constants.TAG_STAR_CACHE) as PddOrderTagstar[]
}

/**
 * 获取订单标星
 * @param id 获取ID
 * @returns
 */
const getPddOrderTagstarInfo = (id:string) => {
  return pddOrderTagstarService.getPddOrderTagstarInfo(pathConfig.pddOrderTagstarFormPath, id)
}

/**
 * 保存订单标星
 * @param pddOrderTagstar
 * @param callback
 * @returns
 */
const savePddOrderTagstar = (pddOrderTagstars:PddOrderTagstar[], callback?:()=>void) => {
  pddOrderTagstarService.savePddOrderTagstar(pddOrderTagstars, pathConfig.pddOrderTagstarSavePath, callback)
}

/**
 * 删除订单标星
 * @param id
 * @param callback
 * @returns
 */
const delPddOrderTagstar = (id:string, callback?:()=>void) => {
  const pddOrderTagstar = new PddOrderTagstarImpl()
  pddOrderTagstar.id = id
  pddOrderTagstarService.delPddOrderTagstar(pddOrderTagstar, pathConfig.pddOrderTagstarDelPath, callback)
}

export { getPddOrderTagstarListPage, getPddOrderTagstarAllList, getPddOrderTagstar, getPddOrderTagstarInfo, savePddOrderTagstar, delPddOrderTagstar }
