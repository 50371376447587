
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent(
  {
    setup () {
      return {
        store
      }
    }
  }
)

