import BaseService from '@/common/service/BaseService'
import { LoginState, SysUser, TokenResponse, UserInfo } from './types'
import constants from '@/common/constant/constants'
import { PageResponse, Result } from '@/common/types'
import router from '@/router'
import { message } from 'ant-design-vue/lib/components'
import config from '@/config'
import store from '@/store'
import { MallCreateRelateRequst } from '@/server/pdd/pddMallInfo/types'
import { initAllOffices } from '../office'
import { loadAllDicts } from '../dicts'
import { loadAllSysArea } from '../sysArea'
import { getPddOrderTagstarAllList } from '@/server/pdd/pddOrderTagstar'
import { getAllCompanies, getTemplateList } from '@/server/pdd/pddTemplate'
import { loadCurrnetMallInfo } from '@/server/pdd/pddMallInfo'
import { localCache } from '@/utils/cache'
import pddPrinter from '@/utils/printer'
import common from '@/utils/common'

class UserService extends BaseService {
  /**
   * 登录成功系统初始化
   * @param tokenResponse
   */
  loginInit (tokenResponse: TokenResponse): void {
    localCache.clearStorage()
    localCache.setStorage(constants.ACCESS_TOKEN, tokenResponse)
    if (tokenResponse.userType !== undefined) {
      store.state.userType = tokenResponse.userType
    }
    if (tokenResponse.userType === '4') {
      const companiesResult = getAllCompanies()
      companiesResult.then((res) => {
        if (res !== undefined) {
          localCache.setSingleStorage(constants.ALL_COMPANIES, res)
        }
      })
      // 初始化官方打印组件
      if (store.state.isInitPrinter === 0) {
        pddPrinter.reConnectPrinter()
      }
      // 初始化标星组件
      getPddOrderTagstarAllList()
      // 初始化用户面单打印模板
      const pddTemplateLists = getTemplateList()
      pddTemplateLists.then((res) => {
        if (res !== undefined) {
          localCache.setSingleStorage(constants.TEMPLATE_CACHE, res)
        }
      })
      // 加载店铺信息
      const getPddMallInfoInfoxResult = loadCurrnetMallInfo()
      getPddMallInfoInfoxResult.then((res) => {
        if (res !== null) {
          localCache.setSingleStorage(constants.MALL_INFO, res)
        }
      })
    } else if (tokenResponse.userType === '9') {
      console.log(tokenResponse.userType)
    } else {
      // 初始化编码机构
      if (tokenResponse.userType !== '6') {
        initAllOffices()
      }
      // 加载系统字典项
      loadAllDicts()
    }
    // 加载地域信息
    // loadAllSysArea()
  }

  /**
   *  用户登录接口
   *  @param loginState
   *  @param loginUrl
   */
  login (
    loginState: LoginState,
    loginUrl: string,
    fcallback?: () => void
  ): void {
    const result = super.postRequst<LoginState, TokenResponse>(
      loginState,
      loginUrl
    )
    result
      .then((res) => {
        // console.log(res)
        if (res.code === 200) {
          this.loginInit(res.data)
          // common.setStorage(constants.ACCESS_TOKEN, res.data)
          router.push('/')
        } else {
          message.error(res.message)
          if (fcallback !== undefined && fcallback !== null) {
            fcallback()
          }
        }
      })
      .catch((error) => {
        console.log(error)
        const errmsg: Result = {
          code: 403,
          msg: '系统异常',
          data: undefined
        }
        return errmsg
      })
  }

  /**
   * 拼多多跳转
   * @param gcode
   * @param loginPddRedirectUrl
   */
  pddLoginRedirect (
    gcode: string,
    gstate: string,
    loginPddRedirectUrl: string
  ): void {
    const params: Record<string, string> = { code: gcode, state: gstate }
    const result = userService.commonGetRequest<
      Record<string, string>,
      TokenResponse
    >(loginPddRedirectUrl, params)
    result
      .then((res) => {
        if (res !== null && res !== undefined) {
          this.loginInit(res)
          // common.setStorage(constants.ACCESS_TOKEN, res)
          router.push('/')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * 拼多多跳转
   * @param token
   * @param loginPddRedirectUrl
   */
  doLoginRedirect (
    token: string,
    logisticNo: string,
    plateType: string,
    loginPddRedirectUrl: string
  ): void {
    const result = userService.commonGetRequest<null, TokenResponse>(
      loginPddRedirectUrl,
      null
    )
    result
      .then((res) => {
        if (res !== null && res !== undefined) {
          localCache.clearStorage()
          localCache.setSingleStorage(constants.ERP_ACCESS_TOKEN, token)
          localCache.setStorage(constants.ACCESS_TOKEN, res)
          router.push({
            name: 'logisticTemplateDesgin',
            params: {
              logisticNo: logisticNo,
              plateType: plateType
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * 拼多多跳转
   * @param token
   * @param loginPddRedirectUrl
   */
  doGoodsBarLoginRedirect (token: string, loginPddRedirectUrl: string): void {
    const result = userService.commonGetRequest<null, TokenResponse>(
      loginPddRedirectUrl,
      null
    )
    result
      .then((res) => {
        if (res !== null && res !== undefined) {
          localCache.clearStorage()
          localCache.setSingleStorage(constants.ERP_ACCESS_TOKEN, token)
          localCache.setStorage(constants.ACCESS_TOKEN, res)
          router.push({
            name: 'GoodsBarDesgin'
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * 店铺关联
   * @param mallCreateRelateRequst
   * @param askRelateUrl
   */
  askRelate (
    mallCreateRelateRequst: MallCreateRelateRequst,
    askRelateUrl: string
  ): void {
    const result = userService.commonPostRequest<
      MallCreateRelateRequst,
      TokenResponse
    >(askRelateUrl, mallCreateRelateRequst)
    result
      .then((res) => {
        if (res !== undefined) {
          this.loginInit(res)
          router.push('/')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * 注册电子面单授权
   * @param code
   * @param registerOutsideWaybillUrl
   */
  registerOutsideWaybill (
    code: string,
    registerOutsideWaybillUrl: string
  ): void {
    const param: Record<string, string> = { code: code }
    userService.commonGetRequestNoRetrun<Record<string, string>>(
      registerOutsideWaybillUrl,
      param,
      () => {
        router.push({
          name: 'waybill',
          params: {
            key: '3'
          }
        })
      }
    )
  }

  /**
   * 检查登录状态
   * @param statusUrl
   * @returns
   */
  loginStatus (
    statusUrl: string,
    success: (res: any) => void,
    failure: (error: any) => void
  ): void {
    const token: TokenResponse = localCache.getStorage(constants.ACCESS_TOKEN)
    if (token !== null && token !== undefined) {
      const result = super.postRequst<null, UserInfo>(null, statusUrl)
      result
        .then((res) => {
          success(res)
        })
        .catch((error) => {
          failure(error)
        })
    } else {
      failure(undefined)
    }
  }

  /**
   * 退出登录状态
   * @param loginOutUrl
   * @returns
   */
  loginOut (loginOutUrl: string): void {
    const token: TokenResponse = localCache.getStorage(constants.ACCESS_TOKEN)
    if (token !== null && token !== undefined) {
      const result = super.postRequst<null, boolean>(null, loginOutUrl)
      result
        .then((res) => {
          // console.log(res.data)
          // 清空本地缓存
          localCache.clearStorage()
          store.state.showFloatButton = 0
          router.push('/login')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  /**
   * 查询用户数据列表
   * @param sysUser 查询条件
   * @param userListUrl 查询路径
   * @returns
   */
  async userList (
    sysUser: SysUser,
    userListUrl: string
  ): Promise<PageResponse<SysUser>> {
    const token: TokenResponse = localCache.getStorage(constants.ACCESS_TOKEN)
    userListUrl =
      userListUrl +
      '?pageIndex=' +
      sysUser.pageIndex +
      '&pageSize=' +
      sysUser.pageSize
    const sysUserListResponse: PageResponse<SysUser> = {
      pageNum: 1,
      pageSize: config.pageSize,
      totalPages: 0,
      totalSize: 0,
      content: undefined
    }
    if (token !== null && token !== undefined) {
      const userList = super.postRequst<SysUser, any>(sysUser, userListUrl)
      await userList.then((res) => {
        if (res.code === 200) {
          if (res.data !== null) {
            sysUserListResponse.pageNum = res.data.pageNum
            sysUserListResponse.pageSize = res.data.pageSize
            sysUserListResponse.totalPages = res.data.totalPages
            sysUserListResponse.totalSize = res.data.totalSize
            const userarray: SysUser[] = []
            sysUserListResponse.content = userarray
            if (res.data.content !== null && res.data.content.length > 0) {
              res.data.content.forEach((element: SysUser) => {
                const user: SysUser = {
                  id: element.id,
                  loginName: element.loginName,
                  name: element.name,
                  password: '',
                  confirmNewPassword: '',
                  email: element.email,
                  phone: element.phone,
                  mobile: element.mobile,
                  photo: element.photo,
                  company: element.company,
                  office: element.office,
                  no: element.no,
                  userType: element.userType,
                  loginIp: element.loginIp,
                  loginDate: common.timestampToDate(element.loginDate!),
                  loginFlag: element.loginFlag,
                  roleIdList: null,
                  remarks: element.remarks
                }
                userarray.push(user)
              })
            }
          }
        } else {
          console.log(res.message)
        }
      })
    } else {
      router.push('/login')
    }
    return sysUserListResponse
  }

  async getUserInfo (id: string, userInfoUrl: string): Promise<SysUser | null> {
    let sysUser: SysUser | null = null
    // const params:{id:string} = { id: id }
    const params: Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, SysUser>(
      params,
      userInfoUrl
    )
    await result.then((res) => {
      if (res.code === 200) {
        sysUser = res.data
      } else {
        message.error(res.message)
        sysUser = null
      }
    })
    return sysUser
  }

  /**
   * 保存用户信息
   * @param sysUser
   * @param userSaveUrl
   * @param callback
   */
  saveUsers (
    sysUser: SysUser,
    userSaveUrl: string,
    callback?: () => void
  ): void {
    // console.log(sysUser)
    // const result = super.postRequstAttach<SysUser, string>(sysUser, userSaveUrl)
    const result = super.postRequst<SysUser, string>(sysUser, userSaveUrl)
    result.then((res) => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  /**
   * 保存用户信息
   * @param sysUser
   * @param userSaveUrl
   * @param callback
   */
  saveCusUsers (
    sysUser: SysUser,
    cusUserSaveUrl: string,
    callback?: () => void
  ): void {
    const result = super.postRequst<SysUser, string>(sysUser, cusUserSaveUrl)
    result.then((res) => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  changePhoto (
    groupid: string,
    changePhotoUrl: string,
    callback?: () => void
  ): void {
    const params: Record<string, string> = { groupid: groupid }
    const result = super.getRequst<Record<string, string>, string>(
      params,
      changePhotoUrl
    )
    result.then((res) => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  /**
   * 删除用户
   * @param sysUser
   * @param delUsersUrl
   * @param callback
   */
  delUsers (sysUser: SysUser, delUsersUrl: string, callback?: () => void): void {
    const result = super.postRequst<SysUser, string>(sysUser, delUsersUrl)
    result.then((res) => {
      if (res.code === 200) {
        message.success(res.message, 1, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }
}

const userService = new UserService()

export default userService
