import config from '@/config'
import {
  LoginState,
  ReLoginState,
  SysUser,
  SysUserImpl,
  TokenResponse
} from './types'
import userService from './UserService'
import constants from '@/common/constant/constants'
import router from '@/router'
import { Encrypt } from '@/utils/secret'
import { MallCreateRelateRequst } from '@/server/pdd/pddMallInfo/types'
import { localCache } from '@/utils/cache'

const pathConfig = {
  loginUrl: config.baseUrl + '/auth/access_token.html',
  loginPathurl: config.baseUrl + '/auth/login.html',
  userStatusUrl: config.baseUrl + '/auth/validate_token.html',
  loginOutUrl: config.baseUrl + '/auth/loginout.html',
  userListUrl: config.baseUrl + '/api/sys/user/list.html',
  userSaveUrl: config.baseUrl + '/api/sys/user/save.do',
  cusUserSaveUrl: config.baseUrl + '/api/sys/user/saveUser.do',
  changePhotoUrl: config.baseUrl + '/api/sys/user/changePhoto.do',
  getUserInfoUrl: config.baseUrl + '/api/sys/user/form.html',
  delUsersUrl: config.baseUrl + '/api/sys/user/del.do',
  loginPddRedirectUrl: config.baseUrl + '/auth/askcode.html',
  askforuserinfoUrl: config.erpApiUrl + '/auth/authTokenx',
  registerOutsideWaybillUrl:
    config.baseUrl + '/api/pdd/mall/reg_outside_waybill.html', // 注册电子面单授权
  askRelateUrl: config.baseUrl + '/auth/askRelate.html', // 店铺关联
  userRegUrl: config.baseUrl + '/auth/access_reg.html'
}

/**
 * 获取从官方通道登录地址
 * @returns
 */
const getLoginPath = (state?: string) => {
  return userService.commonGetRequest<null, string>(
    pathConfig.loginPathurl + '?state=' + state
  )
}

/**
 * 用户登录
 * @param loginState
 * @returns
 */
const userLogin = (loginState: LoginState, fcallback?: () => void) => {
  const loginUrl = pathConfig.loginUrl
  // console.log(loginState)
  loginState.password = Encrypt(loginState.password)
  userService.login(loginState, loginUrl, fcallback)
}

/**
 * 用户注册
 * @param reg
 * @param callback
 */
const userReg = (reg: ReLoginState, callback?: () => void) => {
  userService.commonPostRequestNoReturn<ReLoginState>(
    pathConfig.userRegUrl,
    reg,
    callback
  )
}

/**
 * 用户登录
 * @param loginState
 * @returns
 */
const userLoginx = (loginState: LoginState) => {
  const loginUrl = pathConfig.loginUrl
  // console.log(loginState)
  loginState.password = Encrypt(loginState.password)
  const result = userService.postRequstNormal<LoginState, TokenResponse>(
    loginState,
    loginUrl
  )
  return result
}

/**
 * 检查用户登录状态
 * @returns
 */
const userStatus = (success?: () => void) => {
  userService.loginStatus(
    pathConfig.userStatusUrl,
    (res) => {
      // console.log(res)
      if (res.code !== 200) {
        // 清空本地缓存
        localCache.clearStorage()
        router.push('/login')
      } else {
        if (success !== undefined) {
          success()
        }
      }
    },
    (error) => {
      console.log(error)
      // 清空本地缓存
      localCache.clearStorage()
      router.push('/login')
    }
  )
}

/**
 * 检查用户登录状态
 * @returns
 */
const checkUserStatus = async (success: () => void, failure: () => void) => {
  await userService.loginStatus(
    pathConfig.userStatusUrl,
    (res) => {
      // console.log(res)
      if (res.code !== 200) {
        failure()
      } else {
        success()
      }
    },
    (error) => {
      console.log(error)
      failure()
    }
  )
}

/**
 * 检查用户是否已经登录系统
 */
const loginStatus = () => {
  userService.loginStatus(
    pathConfig.userStatusUrl,
    (res) => {
      if (res.code === 200) {
        router.push('/')
      }
    },
    (error) => {
      console.log(error)
    }
  )
}

/**
 * 获取用户token
 * @returns
 */
const getToken = () => {
  const token: TokenResponse = localCache.getStorage(constants.ACCESS_TOKEN)
  return token
}

const getErpToken = () => {
  const token = localCache.getSingleStorage(constants.ERP_ACCESS_TOKEN)
  return token
}

/**
 * 官方登录回调请求地址
 * @param gcode
 */
const pddLoginRedirect = (gcode: string, state: string) => {
  userService.pddLoginRedirect(gcode, state, pathConfig.loginPddRedirectUrl)
}

/**
 * 官方登录回调请求地址
 * @param gcode
 */
const doLoginRedirect = (
  token: string,
  logisticNo: string,
  plateType: string
) => {
  const reqUrl = pathConfig.askforuserinfoUrl + '/' + token
  userService.doLoginRedirect(token, logisticNo, plateType, reqUrl)
}

/**
 * 官方登录回调请求地址
 * @param gcode
 */
const doGoodsBarLoginRedirect = (token: string) => {
  const reqUrl = pathConfig.askforuserinfoUrl + '/' + token
  userService.doGoodsBarLoginRedirect(token, reqUrl)
}

/**
 * 退出登录
 * @returns
 */
const loginOut = () => {
  userService.loginOut(pathConfig.loginOutUrl)
}

/**
 * 查询用户列表
 * @param sysUser
 * @returns
 */
const userList = (sysUser: SysUser) => {
  return userService.userList(sysUser, pathConfig.userListUrl)
}
/**
 * 保存用户信息
 * @param sysUser 用户信息
 * @returns
 */
const saveUsers = (sysUser: SysUser, callback?: () => void) => {
  return userService.saveUsers(sysUser, pathConfig.userSaveUrl, callback)
}

/**
 * 用户自行保存信息
 * @param sysUser
 * @param callback
 * @returns
 */
const saveCusUsers = (sysUser: SysUser, callback?: () => void) => {
  return userService.saveCusUsers(sysUser, pathConfig.cusUserSaveUrl, callback)
}

/**
 * 获取用户详情
 * @param id
 * @returns
 */
const getUserInfo = (id: string): Promise<SysUser | null> => {
  return userService.getUserInfo(id, pathConfig.getUserInfoUrl)
}

/**
 * 删除用户
 * @param id 需要删除的用户ID
 * @param callback 删除成功回调方法
 * @returns
 */
const delUsers = (id: string, callback?: () => void): void => {
  const sysUser = new SysUserImpl()
  sysUser.id = id
  return userService.delUsers(sysUser, pathConfig.delUsersUrl, callback)
}
/**
 * 更换用户照片
 * @param gorupid
 * @param callback
 * @returns
 */
const changePhoto = (gorupid: string, callback?: () => void): void => {
  return userService.changePhoto(gorupid, pathConfig.changePhotoUrl, callback)
}

/**
 * 店铺关联回调请求地址
 * @param mallCreateRelateRequst
 * @returns
 */
const askRelate = (mallCreateRelateRequst: MallCreateRelateRequst) => {
  userService.askRelate(mallCreateRelateRequst, pathConfig.askRelateUrl)
}

/**
 * 注册电子面单授权
 * @param code
 */
const registerOutsideWaybillx = (code: string) => {
  userService.registerOutsideWaybill(
    code,
    pathConfig.registerOutsideWaybillUrl
  )
}
export {
  getLoginPath,
  userLogin,
  userReg,
  userLoginx,
  checkUserStatus,
  getToken,
  getErpToken,
  pddLoginRedirect,
  askRelate,
  registerOutsideWaybillx,
  userStatus,
  loginStatus,
  loginOut,
  userList,
  saveUsers,
  saveCusUsers,
  getUserInfo,
  delUsers,
  changePhoto,
  doLoginRedirect,
  doGoodsBarLoginRedirect
}
