/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import BaseService from '@/common/service/BaseService'
import { PageResponse } from '@/common/types'
import config from '@/config'
import { message } from 'ant-design-vue'
import { CompanyRequest, PddTemplate } from './types'

class PddTemplateService extends BaseService {
  async getPddTemplateListPage (pddTemplateListPath:string, pddTemplate:PddTemplate):Promise<PageResponse<PddTemplate>> {
    const pddTemplateList = super.postRequstPage<PddTemplate, PageResponse<PddTemplate>>(pddTemplate, pddTemplateListPath)
    let pddTemplateListResponse:PageResponse<PddTemplate> = { pageNum: 1, pageSize: config.pageSize, totalPages: 0, totalSize: 0, content: undefined }
    await pddTemplateList.then(res => {
      if (res.code === 200) {
        pddTemplateListResponse = res.data
      }
    })
    return pddTemplateListResponse
  }

  async getTemplateList (templateListPath:string):Promise<PddTemplate[]|null> {
    const pddTemplateList = super.getRequst<null, PddTemplate[]>(null, templateListPath)
    let pddTemplateListResponse:PddTemplate[]|null = null
    await pddTemplateList.then(res => {
      if (res.code === 200) {
        pddTemplateListResponse = res.data
      }
    })
    return pddTemplateListResponse
  }

  async getLogisticTemplate (templateListPath:string):Promise<PddTemplate[]|null> {
    const logisticTemplate = super.getRequst<null, PddTemplate[]>(null, templateListPath)
    let pddTemplateListResponse:PddTemplate[]|null = null
    await logisticTemplate.then(res => {
      if (res.code === 200) {
        pddTemplateListResponse = res.data
      }
    })
    return pddTemplateListResponse
  }

  async getAllCompanies (allCompaniesPath:string):Promise<CompanyRequest[]|undefined> {
    const companyRequests = super.getRequst<null, CompanyRequest[]>(null, allCompaniesPath)
    let companyListResponse:CompanyRequest[]|undefined
    await companyRequests.then(res => {
      if (res.code === 200) {
        companyListResponse = res.data
      }
    })
    return companyListResponse
  }

  async getWaybill (wpCode:string, wdCode:string, waybillPath:string):Promise<any> {
    const params:Record<string, string> = { wp_code: wpCode, wd_code: wdCode }
    const waybillList = super.getRequst<Record<string, string>, any>(params, waybillPath)
    let waybillResponse:any = null
    await waybillList.then(res => {
      if (res.code === 200) {
        waybillResponse = res.data
      } else {
        message.error(res.message)
      }
    })
    return waybillResponse
  }

  changeSender (id:string, senderid:string, changeSenderPath:string, success?:()=>void):void {
    const params:Record<string, string> = { id: id, senderid: senderid }
    const waybillList = super.getRequst<Record<string, string>, any>(params, changeSenderPath)
    waybillList.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (success !== undefined) {
          success()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  async getPddTemplateInfo (pddTemplateFormPath:string, id:string):Promise<PddTemplate|null> {
    let pddTemplate:PddTemplate|null = null
    const params:Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, PddTemplate>(params, pddTemplateFormPath)
    await result.then(res => {
      if (res.code === 200) {
        pddTemplate = res.data
      } else {
        message.error(res.message)
        pddTemplate = null
      }
    })
    return pddTemplate
  }

  setDefaultTemplate (setDefaultTemplatePath:string, templateId:string, success?:()=>void):void {
    const params:Record<string, string> = { templateId: templateId }
    const result = super.getRequst<Record<string, string>, string>(params, setDefaultTemplatePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (success !== undefined) {
          success()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  cancelDefaultTemplate (cancelDefaultTemplatePath:string, templateId:string, success?:()=>void):void {
    const params:Record<string, string> = { templateId: templateId }
    const result = super.getRequst<Record<string, string>, string>(params, cancelDefaultTemplatePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (success !== undefined) {
          success()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  savePddTemplate (pddTemplate:PddTemplate, pddTemplateSavePath:string, callback?:()=>void):void {
    const result = super.postRequst<PddTemplate, string>(pddTemplate, pddTemplateSavePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  postTemplate (pddTemplate:PddTemplate, postTemplatePath:string, callback?:()=>void):void {
    const result = super.postRequst<PddTemplate, string>(pddTemplate, postTemplatePath)
    result.then(res => {
      if (res.code === 200) {
        if (res.message !== undefined) {
          message.success(res.message)
        } else {
          message.success(res.msg)
        }
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  delPddTemplate (pddTemplate:PddTemplate, pddTemplateDelPath:string, callback?:()=>void):void {
    const result = super.postRequst<PddTemplate, string>(pddTemplate, pddTemplateDelPath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  movePddTemplate (id:string, mtag:number, moveTemplatePath:string, success?:()=>void):void {
    const params:Record<string, string|number> = { id: id, mtag: mtag }
    const result = super.getRequst<Record<string, string|number>, PddTemplate>(params, moveTemplatePath)
    result.then(res => {
      if (res.code === 200) {
        if (success !== undefined) {
          success()
        }
      } else {
        message.error(res.message)
      }
    })
  }
}

const pddTemplateService = new PddTemplateService()

export default pddTemplateService
