/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import BaseService from '@/common/service/BaseService'
import { PageResponse } from '@/common/types'
import config from '@/config'
import { message } from 'ant-design-vue'
import { PddOrderTagstar } from './types'

class PddOrderTagstarService extends BaseService {
  async getPddOrderTagstarListPage (pddOrderTagstarListPath:string, pddOrderTagstar:PddOrderTagstar):Promise<PageResponse<PddOrderTagstar>|undefined> {
    const pddOrderTagstarList = super.postRequstPage<PddOrderTagstar, PageResponse<PddOrderTagstar>>(pddOrderTagstar, pddOrderTagstarListPath)
    let pddOrderTagstarListResponse:PageResponse<PddOrderTagstar>|undefined
    await pddOrderTagstarList.then(res => {
      if (res.code === 200) {
        pddOrderTagstarListResponse = res.data
      }
    })
    return pddOrderTagstarListResponse
  }

  async getPddOrderTagstarInfo (pddOrderTagstarFormPath:string, id:string):Promise<PddOrderTagstar|undefined> {
    let pddOrderTagstar:PddOrderTagstar|undefined
    const params:Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, PddOrderTagstar>(params, pddOrderTagstarFormPath)
    await result.then(res => {
      if (res.code === 200) {
        pddOrderTagstar = res.data
      } else {
        message.error(res.message)
      }
    })
    return pddOrderTagstar
  }

  savePddOrderTagstar (pddOrderTagstars:PddOrderTagstar[], pddOrderTagstarSavePath:string, callback?:()=>void):void {
    const result = super.postRequst<PddOrderTagstar[], string>(pddOrderTagstars, pddOrderTagstarSavePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  delPddOrderTagstar (pddOrderTagstar:PddOrderTagstar, pddOrderTagstarDelPath:string, callback?:()=>void):void {
    const result = super.postRequst<PddOrderTagstar, string>(pddOrderTagstar, pddOrderTagstarDelPath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }
}

const pddOrderTagstarService = new PddOrderTagstarService()

export default pddOrderTagstarService
